import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(({palette, shadow, spacing, breakpoints}) => ({
  heroTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  heroText: {
    backgroundColor: palette.background.default,
    width: '960px',
    borderRadius: spacing(1, 5),
    boxShadow: shadow.keyplayHeader,
    padding: spacing(10, 12),
    zIndex: 3,
    [breakpoints.down('sm')]: {
      padding: spacing(5, 3),
      width: '100%',
    },
  },
  media: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& img, & video': {
      width: '100%',
      height: 'auto',
      borderRadius: spacing(1, 5),
    },
  },
  noPadding: {
    padding: 0,
  },
}))

export default useStyles
