import React from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PropTypes from 'prop-types'
import useStyles from './styles'

const PlayButton = ({onClick}) => {
  const classes = useStyles()

  return (
    <div className={classes.playButton} onClick={onClick}>
      <PlayArrowIcon className={classes.playIcon} />
    </div>
  )
}

PlayButton.propTypes = {
  onClick: PropTypes.func,
}

export default PlayButton
