import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import PlayButton from 'components/UI/PlayButton'
import useStyles from './styles'

const BoxLayout = ({children, media, handlePlayClick, videoIsPlaying}) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.heroTextContainer}>
        <div
          className={classNames(classes.heroText, {
            [classes.noPadding]: media,
          })}
        >
          {media && (
            <div className={classes.media}>
              {media && <div className={classes.media}>{media}</div>}
              {!videoIsPlaying && <PlayButton onClick={handlePlayClick} />}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

BoxLayout.propTypes = {
  children: PropTypes.node,
  media: PropTypes.node,
}

export default BoxLayout
